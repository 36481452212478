<template>
  <base-layout page-title="Factory Reset">
    <template v-slot:main-content>
      <ion-list v-if="isVEDAOnline">
        <ion-item>
          <ion-label>
            Confirm that you want to reset VEDA to its factory settings...
          </ion-label>
        </ion-item>
          <ion-button @click="factoryReset()" expand="block" fill="outline" color="danger">FACTORY RESET</ion-button>
      </ion-list>

      <ion-list v-else>
        <ion-item>
          <ion-label>
            Provide Instructions for resetting the VEDA using the 'Factory Reset' sequence on the VEDA UI.
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            When the VEDA is offline, factory reset is performed using buttons on the VEDA.
          </ion-label>
        </ion-item>
      </ion-list>
    </template>
  </base-layout>
</template>

<script lang="ts">
import { vedaService } from '../services/vedaService'
import { userService } from '../services/userService'
import { defineComponent } from 'vue'
import { alertController } from '@ionic/vue'
import BaseLayout from './BaseLayout.vue'

export default defineComponent({
  name: 'FactoryReset',
  components: {
    BaseLayout
  },
  data: () => {
    return {
      isVEDAOnline: true
    }
  },
  methods: {
    factoryReset: async () => {
      const alert = await alertController.create({
        header: 'Are you sure?',
        message: 'This will delete all data on the device. You will have to manually reconnect the device to the internet again.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Continue with Reset',
            handler: async () => {
              const response = await vedaService.factoryReset()
              if (response && response.data && response.data.data) {
                // window.alert(response.data.data)
                userService.openToast(response.data.data, 'light')
              }
            }
          }
        ]
      })
      alert.present()
      
    }
  }
})
</script>
