
import { vedaService } from '../services/vedaService'
import { userService } from '../services/userService'
import { defineComponent } from 'vue'
import { alertController } from '@ionic/vue'
import BaseLayout from './BaseLayout.vue'

export default defineComponent({
  name: 'FactoryReset',
  components: {
    BaseLayout
  },
  data: () => {
    return {
      isVEDAOnline: true
    }
  },
  methods: {
    factoryReset: async () => {
      const alert = await alertController.create({
        header: 'Are you sure?',
        message: 'This will delete all data on the device. You will have to manually reconnect the device to the internet again.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Continue with Reset',
            handler: async () => {
              const response = await vedaService.factoryReset()
              if (response && response.data && response.data.data) {
                // window.alert(response.data.data)
                userService.openToast(response.data.data, 'light')
              }
            }
          }
        ]
      })
      alert.present()
      
    }
  }
})
